import React, { useContext } from 'react'
import { UserContext } from 'context'
import {
    useScrollTrigger,
    AppBar,
    Box,
    Toolbar,
    Button,
    IconButton,
    Badge,
    Link
} from '@material-ui/core'
import { ShoppingCart as ShoppingCartIcon } from '@material-ui/icons/'
import { CartContext } from 'context/CartContext'
import { Link as RouterLink } from 'react-router-dom'
import useStyles from './Header.styles'

import Avatar from './Avatar'
import Logo from './Logo'

const Header = ({ page }) => {
    const isScrolling = useScrollTrigger({
        disableHysteresis: true,
        threshold: 32
    })

    const { user } = useContext(UserContext)

    const { cartItems } = useContext(CartContext)

    let countItems = cartItems.length

    const loggedIn = user !== null

    const classes = useStyles()

    const withSmallStyles = className => {
        const smallClass = isScrolling ? ' small' : ''
        return `${className}${smallClass}`
    }

    return (
        <AppBar elevation={0} className={`${withSmallStyles(classes.appBar)} ${page.isHome ? 'home' : ''}`}>
            <Toolbar className={withSmallStyles(classes.toolbar)}>
                <Logo />

                <Box flexGrow={1} />

                {!page.isLogin && (
                    <Box className={withSmallStyles(classes.navBar)}>
                        {loggedIn && (
                            <Box className={classes.topMenu}>
                                {page.isHome && (
                                    <Link to="/products" component={RouterLink}>
                                        Browse Sapphires
                                    </Link>
                                )}
                            </Box>
                        )}

                        <Box className={classes.profile}>
                            {loggedIn ? (
                                <>
                                    <RouterLink to="/cart">
                                        <IconButton color="primary" aria-label="add to cart">
                                            <Badge badgeContent={countItems} color="secondary">
                                                <ShoppingCartIcon />
                                            </Badge>
                                        </IconButton>
                                    </RouterLink>
                                    <Avatar />
                                </>
                            ) : (
                                <Button
                                    component={RouterLink}
                                    to="/login"
                                    variant="contained"
                                    color="primary"
                                    className={classes.loginButton}
                                >
                                    Login
                                </Button>
                            )}
                        </Box>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    )
}

export default Header
