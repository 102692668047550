import { useState, forwardRef, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'
import {
    Box,
    Grid,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Hidden,
    IconButton,
    Dialog,
    Paper,
    Fade,
    Slide,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core'
import {
    ArrowBackIos as BackIcon,
    ShoppingCart as ShoppingCartIcon,
    ExpandMore as ExpandMoreIcon
} from '@material-ui/icons/'
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart'
import { Scrollbars } from 'react-custom-scrollbars'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { CartContext } from 'context'
import useStyles from './ProductDetail.styles'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const ProductDetail = ({ stone, getStoneTitle }) => {
    const history = useHistory()

    const { addCart, isInCart, removeCart } = useContext(CartContext)

    const [popupOpen, setPopupOpen] = useState(false)

    const [showMore, setShowMore] = useState(false)

    const [carouselSelected, setCarouselSelectItem] = useState(0)

    const goBack = () => {
        history.push('/products')
    }

    useEffect(() => {
        setCarouselSelectItem(0)
    }, [stone])

    const classes = useStyles()

    return (
        <Box className={classes.productDetail} padding={2}>
            <Dialog
                TransitionComponent={Transition}
                keepMounted
                className={classes.modal}
                open={popupOpen}
                onClose={() => {
                    setPopupOpen(false)
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Fade in={popupOpen}>
                    <Paper className={classes.modalBox}>
                        <Carousel
                            selectedItem={carouselSelected}
                            onChange={evt => setCarouselSelectItem(evt)}
                            showStatus={false}
                        >
                            {stone.medias &&
                                stone.medias.map((media, index) => (
                                    <Box key={index}>
                                        <img src={media[0]} alt={getStoneTitle(stone)} />
                                    </Box>
                                ))}
                        </Carousel>
                    </Paper>
                </Fade>
            </Dialog>
            <Box className={classes.header}>
                <Box display="flex" alignItems="center">
                    <IconButton onClick={goBack}>
                        <BackIcon style={{ fontSize: 22 }} />
                    </IconButton>
                    <Box marginLeft={1}>
                        <Typography variant="h5" className={classes.model} color="primary">
                            {stone.model}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography align="left" variant="subtitle1" color="secondary">
                        ${(Math.floor(stone.rate * stone.weight * 100 + 0.5) / 100).toFixed(2)}
                    </Typography>
                    <Box ml={2}>
                        {isInCart(stone.uuid) ? (
                            <IconButton
                                className={`${classes.removeIcon} ${classes.cartIcon}`}
                                onClick={() => removeCart(stone)}
                            >
                                <RemoveShoppingCartIcon />
                            </IconButton>
                        ) : (
                            <IconButton className={classes.cartIcon} onClick={() => addCart(stone)}>
                                <ShoppingCartIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            </Box>
            <Grid item className={classes.container}>
                <Box className={classes.main}>
                    <Carousel
                        onClickItem={() => {
                            setPopupOpen(true)
                        }}
                        selectedItem={carouselSelected}
                        onChange={evt => setCarouselSelectItem(evt)}
                        showStatus={false}
                    >
                        {stone.medias &&
                            stone.medias.map((media, index) => (
                                <Box key={index}>
                                    <img src={media[0]} alt={getStoneTitle(stone)} />
                                </Box>
                            ))}
                    </Carousel>
                    <Box className={classes.info}>
                        <Typography variant="h5">Information</Typography>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Color</TableCell>
                                    <TableCell align="left">Weight</TableCell>
                                    <TableCell align="left">Shape</TableCell>
                                    <TableCell align="left">Rate</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">{stone.color}</TableCell>
                                    <TableCell align="left">{stone.weight}ct</TableCell>
                                    <TableCell align="left">{stone.shape}</TableCell>
                                    <TableCell align="left">${stone.rate}/ct</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Box mt={1}>
                            <Accordion
                                expanded={showMore}
                                onChange={() => {
                                    setShowMore(showMore => !showMore)
                                }}
                                className={classes.moreInfoPanel}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">More</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="left" variant="head">
                                                    Dimension
                                                </TableCell>
                                                <TableCell align="left">
                                                    {stone.dimension}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" variant="head">
                                                    Treatment
                                                </TableCell>
                                                <TableCell align="left">
                                                    {stone.treatment}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" variant="head">
                                                    Case
                                                </TableCell>
                                                <TableCell align="left">{stone.case}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="left" variant="head">
                                                    Location
                                                </TableCell>
                                                <TableCell align="left">{stone.location}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Box>
    )
}

export default ProductDetail
