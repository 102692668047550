import { useHistory, useLocation } from 'react-router'
import { useContext, useEffect } from 'react'
import { UserContext } from 'context'
import { Auth } from '@aws-amplify/auth'
import { onAuthUIStateChange } from '@aws-amplify/ui-components'

const PUBLIC_PAGES = ['/', '/login']

const AuthHelper = () => {
    const history = useHistory()
    const location = useLocation()

    const { setUser, user } = useContext(UserContext)

    useEffect(() => {
        if (user) return

        const isPublicPath = () => PUBLIC_PAGES.includes(location.pathname)
        Auth.currentAuthenticatedUser()
            .then(userInfo => {
                if (!userInfo || !userInfo.username) {
                    if (!isPublicPath()) {
                        history.push('/login')
                    }
                } else {
                    const {
                        username,
                        attributes: { address, email, phone_number: phoneNumber },
                        signInUserSession: {
                            idToken: {
                                payload: { 'custom:tier': tier, name }
                            }
                        }
                    } = userInfo
                    setUser({
                        username,
                        address,
                        email,
                        phoneNumber,
                        tier,
                        name
                    })
                    if (location.pathname === '/login') {
                        history.push('/')
                    }
                }
            })
            .catch(e => {
                setUser(null)

                if (!isPublicPath()) {
                    history.push('/login')
                }
            })
    }, [user, history, location])

    useEffect(() => {
        return onAuthUIStateChange((nextAuthState, userInfo) => {
            if (nextAuthState === 'signedin') {
                const {
                    username,
                    attributes: { address, email, phone_number: phoneNumber },
                    signInUserSession: {
                        idToken: {
                            payload: { 'custom:tier': tier, name }
                        }
                    }
                } = userInfo
                setUser({
                    username,
                    address,
                    email,
                    phoneNumber,
                    tier,
                    name
                })
                history.push('/products')
            } else if (nextAuthState === 'signedout') {
                history.push('/')
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return ''
}

export default AuthHelper
