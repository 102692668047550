import React, { useEffect } from 'react'
import { AmplifyAuthenticator } from '@aws-amplify/ui-react'
import 'amplify/style.css'

const Login = () => {
    useEffect(() => {
        // const style = document.createElement('style')
        // style.innerHTML = 'button {border-radius: 3px}'

        // const amplifyButtons = document.getElementsByTagName('amplify-authenticator')[0].shadowRoot.children[0].getElementsByTagName('amplify-sign-in')[0].shadowRoot.children[0].getElementsByTagName('amplify-button')
        // for (let i = 0; i < amplifyButtons.length; i++) {
        //     // console.log(amplifyButtons[i].shadowRoot)
        //     amplifyButtons[i].shadowRoot.appendChild(style)
        // }
    }, [])
    return <AmplifyAuthenticator />
}

export default Login
