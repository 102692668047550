const typegraphy = {
    htmlFontSize: 24,
    fontFamily: '"Assistant", "Poppins", "Helvetica", "Arial", sans-serif',
    fontSize: 24,
    h1: {
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
        fontSize: 180,
        fontWeight: '500'
    },
    h2: {
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
        fontSize: 60,
        fontWeight: '500'
    },
    h3: {
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
        fontSize: 40,
        fontWeight: 'normal',
        color: '#143863'
    },
    h5: {
        fontWeight: 'bold',
        fontSize: 32
    },
    body1: {
        fontWeight: 'normal',
        fontSize: 18
    },
    body2: {
        fontWeight: 'normal',
        fontSize: 22
    },
    subtitle1: {
        fontSize: 24
    },
    button: {
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
        fontSize: 16,
        fontWeight: 'normal',
        textTransform: 'none'
    }
}

export default typegraphy
