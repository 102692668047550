import { Box, Button, Grid, Typography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

import useStyles from './Features.styles'

const AboutUs = () => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Grid container spacing={8}>
                <Grid xs={12} item md={6}>
                    <Box className={classes.feature}>
                        <Typography variant="h3" className={classes.title}>
                            Our Process
                        </Typography>
                        <Box marginTop={3} className={classes.content}>
                            <Typography variant="body2">
                                We believe in a multi-step, redundant approach to stone grading and
                                validation. We test stones in the field at the source and again in
                                one of our offices before shipping to the US. We partner with
                                American Gemological Labs and Gemological Institute of America Labs
                                for certification. It is because of this extensive process that we
                                are able to guarantee that our stones are natural, never synthetic,
                                as well as the type of heat treatment (if any) the stone has
                                undergone.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={12} item md={6}>
                    <Box className={classes.feature}>
                        <Typography variant="h3" className={classes.title}>
                            Sales
                        </Typography>
                        <Box marginTop={3} className={classes.content}>
                            <Typography variant="body2">
                                We take a similar approach in selecting our customers just as we do
                                with our suppliers.
                                <br />
                                <br />
                                Please contact us for more information or to submit a request to be
                                evaluated as a new customer. If you're already a customer, we're
                                happy you're here. Please sign in to browse our current inventory.
                            </Typography>
                        </Box>
                        <Box marginTop={3}>
                            <Button
                                component={RouterLink}
                                to="/login"
                                variant="contained"
                                color="secondary"
                                className={classes.loginButton}
                            >
                                Login Now
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AboutUs
