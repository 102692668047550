import { forwardRef } from 'react'
import {
    Dialog,
    Slide,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button
} from '@material-ui/core'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const ConfirmDialog = ({ open, handleClose, confirm }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="company.add.dialog.title.background_check_confirm"
            aria-describedby="company.add.dialog.title.background_check_confirm"
        >
            <DialogTitle>Confirm Checkout</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Have you confirmed that all the order details you have selected are correct? Are
                    you sure to continue?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    No
                </Button>
                <Button onClick={confirm} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog
