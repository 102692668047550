import React from 'react'
import { Switch } from 'react-router-dom'
import Main from 'layouts/Main'
import RouteWithLayout from 'components/shared/RouteWithLayout'

import Home from 'pages/Home'
import Product from 'pages/Product'
import Cart from 'pages/Cart'
import Login from 'pages/Login'
import ComingSoon from 'layouts/coming-soon'

const AppRouter = () => (
    <Switch>
        <RouteWithLayout layout={ComingSoon} exact path="/" component={Home} />

        <RouteWithLayout layout={Main} Route exact path="/login" component={Login} />

        {/* <RouteWithLayout layout={ComingSoon} exact path="/" component={Home} /> */}
        <RouteWithLayout layout={Main} exact path="/products" component={Product} />
        <RouteWithLayout layout={Main} exact path="/products/:uuid" component={Product} />
        <RouteWithLayout layout={Main} exact path="/cart" component={Cart} />
    </Switch>
)

export default AppRouter
