import { Box, Container, IconButton, Link, Typography } from '@material-ui/core'
import { SportsEsports, Twitter } from '@material-ui/icons'
import useStyles from './Footer.styles'

const Footer = () => {
    const classes = useStyles()

    return (
        <Box className={classes.root} textAlign="center">
            <Container maxWidth="xl">
                <Box padding={2}>
                    <Typography variant="body2" className={classes.copyright}>
                        © Copyright&nbsp;
                        <span className={classes.companyName}>Sheahan Stephen Sapphires Inc</span>
                        &nbsp;2022. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Footer
