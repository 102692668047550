import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    appBar: {
        transition: 'background, height, border-bottom 0.5s',
        background: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        '&.home': {
            background: 'transparent',
            borderBottom: `none`
        },
        // color: theme.palette.text.primary,
        '&.small': {
            background: '#ffffffee',
            boxShadow: '0 0 4px rgba(0, 0, 0, .25)'
        },
        '&.home.small': {
            background: '#33217C99'
        }
        // '&.light': {
        //     background: 'transparent',
        //     boxShadow: 'none'
        // }
    },
    toolbar: {
        transition: 'all 0.5s',
        height: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            height: theme.spacing(8)
        },
        [theme.breakpoints.up('md')]: {
            height: theme.spacing(16)
        },
        '&.small': {
            [theme.breakpoints.up('sm')]: {
                height: theme.spacing(7)
            },
            [theme.breakpoints.up('md')]: {
                height: theme.spacing(8)
            }
        }
    },
    navBar: {
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.5s',
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(-5)
        },
        '&.small': {
            [theme.breakpoints.up('md')]: {
                marginTop: 0
            }
        }
    },
    topMenu: {},
    profile: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(4)
    },
    loginButton: {
        textTransform: 'uppercase',
        fontSize: 18,
        fontWeight: '600',
        paddingLeft: 36,
        paddingRight: 36,
        paddingTop: 4,
        paddingBottom: 4,
        borderRadius: 30
    }
}))

export default useStyles
