import { Auth, Hub } from 'aws-amplify'
import { useState, useContext } from 'react'
import { useHistory } from 'react-router'
import { UserContext } from 'context'
import useStyles from './Avatar.styles'

import { Box, IconButton, Menu, Typography, Button, Divider } from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'

const Avatar = () => {
    const [avatarAnchorEl, setAvatarAnchorEl] = useState(null)
    const profileMenuOpen = Boolean(avatarAnchorEl)

    const { user, setUser } = useContext(UserContext)

    const history = useHistory()

    Hub.listen('auth', data => {
        switch (data.payload.event) {
            case 'signOut':
                setUser(null)
                history.push('/')
                break
            default:
                break
        }
    })

    const handleAvatarClick = event => {
        setAvatarAnchorEl(event.currentTarget)
    }

    const handleProfileMenuClose = () => {
        setAvatarAnchorEl(null)
    }

    const signOut = () => {
        Auth.signOut()
        handleProfileMenuClose()
    }

    const classes = useStyles()

    return (
        <div>
            <IconButton
                color="primary"
                className={classes.avatarButton}
                onClick={handleAvatarClick}
            >
                <AccountCircle className={classes.avatarIcon} />
            </IconButton>
            <Menu
                id="menu-appbar"
                getContentAnchorEl={null}
                anchorEl={avatarAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                classes={{ paper: classes.menu }}
                open={profileMenuOpen}
                onClose={handleProfileMenuClose}
            >
                {user && (
                    <Box p={2}>
                        <Typography component="h6" variant="subtitle2">
                            {user.username}
                        </Typography>
                        <Typography component="h6" variant="subtitle2" color="textSecondary">
                            {user.email}
                        </Typography>
                    </Box>
                )}
                {user && <Divider />}
                {/* <Box mt={2}>
					<MenuItem onClick={handleMyAccountClick}>
						<ListItemIcon>
							<PersonIcon />
						</ListItemIcon>
						<Typographycomponent="h6"
                            variant="subtitle2">
							Profile
						</Typographycomponent=>
					</MenuItem>
				</Box> */}
                <Box p={2}>
                    <Button onClick={signOut} fullWidth variant="outlined" color="primary">
                        Sign Out
                    </Button>
                </Box>
            </Menu>
        </div>
    )
}

export default Avatar
