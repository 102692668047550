import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    banner: {
        position: 'relative',
        width: '100%',
        height: '100vh',
        // minHeight: '870px',
        background: 'url(/images/background.png) bottom no-repeat, linear-gradient(150deg, #33217C 0%, #434AD9 100%)',
        backgroundSize: '100%',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(8),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(14),
            textAlign: 'left'
        }
    },
    welcome: {
        marginTop: '20vh',
        [theme.breakpoints.up('md')]: {
            marginTop: '15vh',
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        },
        color: '#fff',
    },
    learnMoreButton: {
        background: 'linear-gradient(150deg, #B92869 0%, #C3746D 100%)',
        color: '#fff',
        fontSize: theme.spacing(4),
        borderRadius: theme.spacing(8),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6)
    }
}))

export default useStyles
