import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {},
    container: {
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    filter: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
            width: theme.spacing(30),
            flexShrink: 0
        }
    },
    content: {
        flexGrow: 1
    },
    loadMoreWrapper: {
        textAlign: 'center',
        position: 'relative',
        marginBottom: theme.spacing(2)
    },
    loadMoreButton: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6)
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: '-16px',
        marginTop: '-16px'
    },
    filterIcon: {
        position: 'fixed',
        left: theme.spacing(1),
        top: theme.spacing(9),
        zIndex: '99',
        minWidth: 'inherit',
        padding: theme.spacing(1)
    }
}))

export default useStyles
