import ReactDOM from 'react-dom'
import { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import {
    Paper,
    Container,
    Grid,
    Button,
    IconButton,
    Hidden,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    CircularProgress
} from '@material-ui/core'
import { ArrowBackIos as BackIcon } from '@material-ui/icons/'
import axios from 'utils/axios'
import useStyles from './Cart.styles'
import ConfirmDialog from './confirmDialog'
import { CartContext, UserContext } from 'context'
import emailjs from 'emailjs-com'
import { Close as CloseIcon } from '@material-ui/icons'
import { EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_USER_ID } from 'config/constants'

const Product = () => {
    const [totalAmount, setTotal] = useState(0)

    const { cartItems, checkoutCart, removeCart } = useContext(CartContext)

    const { user } = useContext(UserContext)

    const [confirmModal, setConfirmModal] = useState(false)

    const [loading, setLoading] = useState(false)

    const history = useHistory()
    const handleGoToListClick = () => {
        history.push('/products')
    }

    const getCartData = () => {
        return (
            <>
                <p>Customer Name: {user.name}</p>
                <p>Send to Address: {user.address}</p>
                <p>Phone Number: {user.phoneNumber}</p>
                <p>Email: {user.email}</p>
                <table
                    border="1"
                    cellSpacing="0"
                    cellPadding="5"
                    style={{ width: '100%', borderCollapse: 'collapse' }}
                >
                    <thead>
                        <tr>
                            <th align="left"></th>
                            <th align="left">Model</th>
                            <th align="left">Color</th>
                            <th align="left">Weight</th>
                            <th align="left">Dimension</th>
                            <th align="left">Rate</th>
                            <th align="right">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartItems.map(stone => {
                            let primaryMedia = stone.primaryMedia ||
                                stone.media || ['images/no-media.jpg']
                            return (
                                <tr key={stone.id + 'export'}>
                                    <td align="left">
                                        <img
                                            alt="product-img"
                                            src={primaryMedia[0]}
                                            width="60"
                                            height="60"
                                        />
                                    </td>
                                    <td align="left">{stone.model}</td>
                                    <td align="left">{stone.color}</td>
                                    <td align="left">{stone.weight}ct</td>
                                    <td align="left">{stone.dimension}</td>
                                    <td align="left">${stone.rate}/ct</td>
                                    <td align="right">
                                        $
                                        {(
                                            Math.floor(stone.rate * stone.weight * 100 + 0.5) / 100
                                        ).toFixed(2)}
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <td className={classes.noColumn} colSpan="5" />
                            <td>Subtotal</td>
                            <td align="right">${totalAmount.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td className={classes.noColumn} colSpan="5" />
                            <td>Shipping</td>
                            <td align="right">$0.00</td>
                        </tr>
                        <tr>
                            <td className={classes.noColumn} colSpan="5" />
                            <td className={classes.total}>Total</td>
                            <td align="right">${totalAmount.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </>
        )
    }

    const confirm = () => {
        setConfirmModal(true)
    }

    const handleClose = () => {
        setConfirmModal(false)
    }

    const checkout = () => {
        handleClose()
        setLoading(true)
        emailjs.init(EMAILJS_USER_ID)
        let div = document.createElement('div')
        ReactDOM.render(getCartData(), div)
        let data = {
            from_name: user.name,
            message: div.innerHTML
        }
        let uuids = cartItems.map(item => item.uuid)

        axios
            .post('stones/checkout', uuids)
            .then(response => {
                checkoutCart()
                setLoading(false)
                emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, data).then(
                    response => {},
                    function (err) {
                        console.log(err)
                    }
                )
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                history.push('/products')
            })
    }

    useEffect(() => {
        let total = 0
        let forEach = _ => {
            cartItems.forEach(stone => {
                total = total + Math.floor(stone.rate * stone.weight * 100 + 0.5) / 100
            })
            setTotal(total)
        }
        forEach()
    })

    const classes = useStyles()
    return (
        <Container maxWidth="md" className={classes.root}>
            <ConfirmDialog open={confirmModal} handleClose={handleClose} confirm={checkout} />
            <Paper elevation={0} className={classes.container}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid container alignItems="center" className={classes.headerItem}>
                        <IconButton onClick={handleGoToListClick}>
                            <BackIcon style={{ fontSize: 22 }} />
                        </IconButton>
                        <Typography variant="h5" color="primary">Order</Typography>
                    </Grid>
                    {cartItems.length !== 0 && (
                        <Grid container alignItems="center" className={classes.buttonContainer}>
                            {loading && <CircularProgress size={36} className={classes.loading} />}
                            <Button
                                disabled={loading}
                                onClick={confirm}
                                variant="contained"
                                color="secondary"
                            >
                                Checkout
                            </Button>
                        </Grid>
                    )}
                </Grid>
                {cartItems.length === 0 ? (
                    <Typography className={classes.noCart} variant="body1">
                        There are no added items in your cart.
                    </Typography>
                ) : (
                    <>
                        <Grid item className={classes.tableWrapper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left">Model</TableCell>
                                        <Hidden smDown>
                                            <TableCell align="left">Color</TableCell>
                                            <TableCell align="left">Weight</TableCell>
                                            <TableCell align="left">Dimension</TableCell>
                                        </Hidden>
                                        <TableCell align="left">Rate</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cartItems.map((stone, index) => {
                                        let primaryMedia = stone.primaryMedia ||
                                            stone.media || ['/images/no-media.jpg']
                                        return (
                                            <TableRow key={index}>
                                                <TableCell
                                                    align="left"
                                                    className={classes.tableImg}
                                                >
                                                    <img
                                                        alt="product-img"
                                                        src={primaryMedia[0]}
                                                        width="60"
                                                        height="60"
                                                    />
                                                </TableCell>
                                                <TableCell align="left">{stone.model}</TableCell>
                                                <Hidden smDown>
                                                    <TableCell align="left">
                                                        {stone.color}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {stone.weight}ct
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {stone.dimension}
                                                    </TableCell>
                                                </Hidden>
                                                <TableCell align="left">${stone.rate}/ct</TableCell>
                                                <TableCell align="right">
                                                    $
                                                    {(
                                                        Math.floor(
                                                            stone.rate * stone.weight * 100 + 0.5
                                                        ) / 100
                                                    ).toFixed(2)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        onClick={() => removeCart(stone)}
                                                        variant="outlined"
                                                        color="secondary"
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    <TableRow>
                                        <TableCell className={classes.noColumn} colSpan="2" />
                                        <Hidden smDown>
                                            <TableCell className={classes.noColumn} colSpan="3" />
                                        </Hidden>
                                        <TableCell>Subtotal</TableCell>
                                        <TableCell align="right">
                                            ${totalAmount.toFixed(2)}
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.noColumn} colSpan="2" />
                                        <Hidden smDown>
                                            <TableCell className={classes.noColumn} colSpan="3" />
                                        </Hidden>
                                        <TableCell>Shipping</TableCell>
                                        <TableCell align="right">$0.00</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.noColumn} colSpan="2" />
                                        <Hidden smDown>
                                            <TableCell className={classes.noColumn} colSpan="3" />
                                        </Hidden>
                                        <TableCell className={classes.total}>Total</TableCell>
                                        <TableCell align="right">
                                            ${totalAmount.toFixed(2)}
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid className={classes.bottomWrapper} container direction="row-reverse">
                            <Grid container alignItems="center" className={classes.buttonContainer}>
                                {loading && (
                                    <CircularProgress size={36} className={classes.loading} />
                                )}
                                <Button
                                    disabled={loading}
                                    onClick={confirm}
                                    variant="contained"
                                    color="secondary"
                                >
                                    Checkout
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Paper>
        </Container>
    )
}

export default Product
