import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {},
    categoryGroup: {
        // padding: theme.spacing(1)
    },
    categoryGroupLabel: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
        textTransform: 'capitalize',
    },
    categoryGroupItems: {
        marginLeft: theme.spacing(1)
    },
    checkbox: {
        // color: theme.palette.primary.main,
        padding: 3,
        paddingLeft: 9
    }
}))

export default useStyles
