import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        }
    },
    headerItem: {
        width: 'fit-content'
    },
    container: {
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
        position: 'relative'
    },
    tableImg: {
        padding: '4px',
        '& img': {
            objectFit: 'cover'
        }
    },
    noColumn: {
        border: 'none'
    },
    total: {
        fontWeight: 'bold'
    },
    blankContainer: {
        height: '300px',
        color: '#0004'
    },
    bottomWrapper: {
        padding: `${theme.spacing(1)}px 0px`
    },
    noCart: {
        paddingInlineStart: theme.spacing(6),
        marginTop: '15px'
    },
    tableWrapper: {
        position: 'relative',
        height: 'fit-content',
        width: '100%',
        overflow: 'auto'
    },
    modal: {
        width: '80vw'
    },
    modalBox: {
        width: '100%'
    },
    loading: {
        marginRight: theme.spacing(1)
    },
    buttonContainer: {
        width: 'fit-content'
    }
}))

export default useStyles
