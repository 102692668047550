const COLOR_KEYS = ['PUR', 'PIN', 'YEL', 'ORA', 'PEA', 'GRE', 'BLU', 'RUB', 'PAD', 'WHI']

const Prices = [
    [
        'tier1',
        [
            ['PUR', -5],
            ['PIN', -5],
            ['YEL', -10],
            ['ORA', -5],
            ['PEA', -5],
            ['GRE', -10],
            ['BLU', -2.5],
            ['RUB', -2.5],
            ['PAD', -2.5],
            ['WHI', -2.5]
        ]
    ],
    [
        'tier2',
        [
            ['PUR', -2.5],
            ['PIN', -2.5],
            ['YEL', -5],
            ['ORA', -2.5],
            ['PEA', -2.5],
            ['GRE', -5],
            ['BLU', 0],
            ['RUB', 0],
            ['PAD', 0],
            ['WHI', -2.5]
        ]
    ],
    [
        'tier3',
        [
            ['PUR', 0],
            ['PIN', 0],
            ['YEL', 0],
            ['ORA', 0],
            ['PEA', 0],
            ['GRE', 0],
            ['BLU', 0],
            ['RUB', 0],
            ['PAD', 0],
            ['WHI', 0]
        ]
    ],
    [
        'tier4',
        [
            ['PUR', 2.5],
            ['PIN', 2.5],
            ['YEL', 0],
            ['ORA', 2.5],
            ['PEA', 2.5],
            ['GRE', 0],
            ['BLU', 2.5],
            ['RUB', 2.5],
            ['PAD', 2.5],
            ['WHI', 0]
        ]
    ],
    [
        'tier5',
        [
            ['PUR', 35],
            ['PIN', 35],
            ['YEL', 25],
            ['ORA', 30],
            ['PEA', 35],
            ['GRE', 25],
            ['BLU', 35],
            ['RUB', 35],
            ['PAD', 35],
            ['WHI', 30]
        ]
    ]
]

const CUSTOMIZED_COLORS = [
    ['PIN', 'Pink'],
    ['BLU', 'Blue', 'Blue CAB'],
    ['YEL', 'Yellow', 'Yellow/Green'],
    ['PUR', 'Purple'],
    ['ORA', 'Orange'],
    ['WHI', 'White'],
    ['PEA', 'Peach'],
    ['PAD', 'Padparadscha']
]
// Unknown Colors: Chrysoberyl Cats Eye, CC, Teal

const allColors = COLOR_KEYS.map(color => [color])

CUSTOMIZED_COLORS.forEach(colors => {
    const index = allColors.findIndex(([color]) => colors[0] === color)
    allColors.splice(index, 1, colors)
})

const getCorrectColor = color => {
    const colors = allColors.find(colors => colors.includes(color))
    if (!colors) return color

    return colors[0]
}

export const getPrice = (price, color, tier) => {
    if (!tier) return price

    color = getCorrectColor(color)
    const customerPrice = Prices.find(prices => prices[0] === tier)[1]
    const priceRate = customerPrice.find(rate => rate[0] === color)

    if (!priceRate) {
        console.warn('Can not find Color:', color)
        return price
    }

    return price * (1 + priceRate[1] * 0.01)
}
