const API_URL = process.env.REACT_APP_API_URL

const LANGUAGES = {
    en: 'English'
}
const LOCALES = {
    en: 'enUS'
}

const DEFAULT_LANGUAGE = Object.keys(LANGUAGES)[0]

const AWS_AUTH_CONFIG = {
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID
}

const PAGE_SIZE = 12

const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID
const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID

const COLORS = ["Blue", "Blue CAB", "Chrysoberyl Cats Eye", "Teal", "Yellow/Green", "Pink", "Purple", "Orange", "White", "Yellow", "Padparadscha", "Peach", "CC"]
const WEIGHTS = [0.1, 0.5, 1.0, 5.0]

const NO_MEDIA_PLACEHOLDER = '/images/no-media.png'

export {
    LANGUAGES,
    DEFAULT_LANGUAGE,
    LOCALES,
    AWS_AUTH_CONFIG,
    API_URL,
    EMAILJS_USER_ID,
    EMAILJS_TEMPLATE_ID,
    EMAILJS_SERVICE_ID,
    PAGE_SIZE,
    COLORS,
    WEIGHTS,
    NO_MEDIA_PLACEHOLDER
}
