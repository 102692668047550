import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        // transition: 'padding-top 0.25s',
        paddingTop: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(8)
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(16)
        },
        '&.home': {
            paddingTop: theme.spacing(0)
        }
    },
    content: {
        minHeight: `calc(100vh - ${theme.spacing(16)}px - 71px)`
    }
}))

export default useStyles
