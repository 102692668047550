import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    productDetail: {
    },
    header: {
        display: 'flex',
        // backgroundColor: 'rgba(0, 0, 0, 0.35)',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    container: {
        marginTop: theme.spacing(2),
        width: '100%',
        height: `calc(100% - ${theme.spacing(4 + 2)}px)`,
        overflowY: 'auto',
        position: 'relative',
        '&>div:first-child': {
            '&>div:last-child': {
                right: '8px !important'
            }
        },
        [theme.breakpoints.down('sm')]: {
            '&>div>div:first-child': {
                position: 'relative !important',
                overflow: 'hidden !important',
                marginRight: '0px !important'
            }
        }
    },
    main: {
        '& .thumb.selected': {
            border: `1px solid ${theme.palette.primary.light}`
        },
        '& .slide>div': {
            height: '350px',
            borderRadius: theme.spacing(0.5),
            border: '1px solid #00000010',
            cursor: 'pointer',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: theme.spacing(0.5)
            }
        },
        '& .thumbs': {
            textAlign: 'center'
        },
        '& .thumb': {
            height: '80px',
            width: '100px',
            borderRadius: theme.spacing(0.5),
            border: '1px solid #00000010',
            cursor: 'pointer',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }
        },
        '& .thumb:hover': {
            borderColor: theme.palette.primary.light
        },
        '& .carousel .control-dots .dot': {
            boxShadow: 'none',
            border: '1px solid #0004',
            '&:hover': {
                borderColor: theme.palette.primary.light
            }
        },
        '& .carousel .control-dots .selected': {
            borderColor: theme.palette.primary.light
        },
        '& .carousel .control-next.control-arrow:before': {
            borderLeft: '8px solid #0009'
        },
        '& .carousel .control-prev.control-arrow:before': {
            borderRight: '8px solid #0009'
        }
    },
    media: {
        width: '100%',
        height: '40vh',
        [theme.breakpoints.down('xs')]: {
            height: '36vh'
        }
    },
    image: {
        width: '100%',
        height: '100%',
        display: 'block',
        objectFit: 'cover',
        borderRadius: theme.spacing(0.5),
        border: '1px solid #00000010'
    },
    thumbnailList: {
        padding: theme.spacing(1)
    },
    thumbnail: {
        width: '100%',
        height: '100px',
        [theme.breakpoints.down('xs')]: {
            height: '64px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '100px'
        },
        [theme.breakpoints.down('md')]: {
            height: '64px'
        },
        cursor: 'pointer',
        '& img': {
            transition: `all ${theme.transitions.duration.short}ms`,
            width: '100%',
            height: '100%',
            borderRadius: theme.spacing(0.5),
            border: '1px solid #00000010',
            display: 'block',
            objectFit: 'cover'
        },
        '&:hover img': {
            borderColor: theme.palette.primary.light
        }
    },

    info: {
        marginTop: theme.spacing(2),
        // padding: theme.spacing(1)
        '& table': {
            marginTop: theme.spacing(0.5),
            '& th, & td': {
                border: `1px solid ${theme.palette.grey[200]}`
                // width: '25%'
            }
        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiDialog-paperWidthSm': {
            width: '80vw',
            maxWidth: '800px'
        }
    },
    modalBox: {
        padding: theme.spacing(2),
        '& img': {
            height: '80vh',
            width: 'auto',
            maxWidth: '80vw',
            objectFit: 'cover',
            borderRadius: theme.spacing(0.5)
        },
        '& .thumb.selected': {
            border: `1px solid ${theme.palette.primary.light}`
        },
        '& .slide>div': {
            height: '60vh',
            borderRadius: theme.spacing(0.5),
            border: '1px solid #00000010',
            cursor: 'pointer',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: theme.spacing(0.5)
            }
        },
        '& .thumb': {
            height: '80px',
            width: '100px',
            borderRadius: theme.spacing(0.5),
            border: '1px solid #00000010',
            cursor: 'pointer',
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }
        },
        '& .thumb:hover': {
            borderColor: theme.palette.primary.light
        },
        '& .carousel .control-dots .dot': {
            boxShadow: 'none',
            border: '1px solid #0004',
            '&:hover': {
                borderColor: theme.palette.primary.light
            }
        },
        '& .carousel .control-dots .selected': {
            borderColor: theme.palette.primary.light
        },
        '& .carousel .control-next.control-arrow:before': {
            borderLeft: '8px solid #0009'
        },
        '& .carousel .control-prev.control-arrow:before': {
            borderRight: '8px solid #0009'
        }
    },
    cartIcon: {
        height: '36px',
        width: '36px',
        background: theme.palette.primary.main,
        color: 'white',
        padding: theme.spacing(1),
        '& svg': {
            width: '20px'
        },
        '&:hover': {
            background: theme.palette.primary.main,
            opacity: '0.8'
        }
    },
    removeIcon: {
        background: theme.palette.secondary.main,
        '&:hover': {
            background: theme.palette.secondary.main,
            opacity: '0.8'
        }
    },
    moreInfoPanel: {
        // margin: 0,
        padding: 0,
        boxShadow: 'none',

        '& .MuiAccordionSummary-root': {
            padding: 0,
            '& .MuiAccordionSummary-content': {
                margin: 0
            },
            '& .MuiAccordionSummary-expandIcon': {
                margin: 0
            }
        },
        '& .MuiAccordionDetails-root': {
            padding: 0
        }
    }
}))

export default useStyles
