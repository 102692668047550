import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4),
        background: 'url(/images/background-dot.png)  no-repeat, #090909',
        color: '#D0D0D0',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        }
    },
    content: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        }
    },
    title: {
        color: '#fff'
    },
    logo: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(4)
        }
    },
    companyName: {
        paddingLeft: theme.spacing(4),
        marginBottom: theme.spacing(1),
        textAlign: 'left',
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: '50%',
            marginTop: -theme.spacing(0.75),
            width: theme.spacing(1.5),
            height: theme.spacing(1.5),
            background: '#fff',
            borderRadius: theme.spacing(1)
        }
    },
    socialLink: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        marginBottom: theme.spacing(1)
    },
    socialName: {
        color: '#D0D0D0',
        marginLeft: theme.spacing(2)
    },

    facebookIcon: {
        background: '#3776AB'
    },
    instagramIcon: {
        background: '#DE1FCA'
    },
    linkedInIcon: {
        background: '#30A4E0'
    },
    elementTitle: {
        color: '#89CFF0'
    }
}))

export default useStyles
