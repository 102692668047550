import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8)
    },
    content: {
        [theme.breakpoints.up('md')]: {
            background: 'url(/images/background-dot-vertical.png) no-repeat',
            backgroundSize: '180px',
            paddingLeft: theme.spacing(28)
        }
    }
}))

export default useStyles
