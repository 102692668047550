import React from 'react'
import { useLocation } from 'react-router'
import { Box } from '@material-ui/core'
import Header from './components/Header'
import useStyles from './Main.styles'
import Footer from './components/Footer'

const Main = props => {
    const { children } = props

    const classes = useStyles()

    const location = useLocation()

    const page = {
        isHome: location.pathname==='/',
        isLogin: location.pathname.startsWith('/login')
    }

    return (
        <Box className={`${classes.root} ${page.isHome ? 'home' : ''}`}>
            <Header page={page} />
            <Box className={`${classes.content} ${page.isHome ? 'home' : ''}`}>{children}</Box>
            <Footer />
        </Box>
    )
}

export default Main
