import { useState } from 'react'
import {
    // Box,
    Checkbox,
    FormGroup,
    FormLabel,
    FormControl,
    FormControlLabel,
    List,
    ListItem
} from '@material-ui/core'
import useStyles from './Filter.styles'

const FilterGroup = props => {
    const classes = props.classes

    return (
        <FormControl className={classes.categoryGroup}>
            <FormLabel className={classes.categoryGroupLabel}>{props.category}</FormLabel>
            <FormGroup className={classes.categoryGroupItems}>
                {props.values &&
                    props.values.map((value, key) => {
                        if (typeof value === 'object') {
                            if (value[0] === null) {
                                value = `Under ${value[1]}`
                            } else if (value[1] === null) value = `Above ${value[0]}`
                            else {
                                value = value.join(' - ')
                            }
                        }
                        return (
                            <FormControlLabel
                                key={value}
                                control={
                                    <Checkbox
                                        className={classes.checkbox}
                                        onChange={() => props.handleChange(props.category, key)}
                                        name={value}
                                        checked={props.checkedValues[key]}
                                        style={{fontSize: 10}}
                                    />
                                }
                                label={value}
                            />
                        )
                    })}
            </FormGroup>
        </FormControl>
    )
}

const Filter = props => {
    const classes = useStyles()

    let initialState = props.filterAttributes.map(filterAttribute => {
        let checkedValues = []
        filterAttribute.values.forEach(value => {
            checkedValues.push(false)
        })

        return { ...filterAttribute, checkedValues }
    })

    const [currentState, setCurrentState] = useState(initialState)

    const handleChange = (category, index) => {
        const nextState = currentState.map(state => {
            return state.category === category
                ? {
                      ...state,
                      checkedValues: state.checkedValues.map((value, key) =>
                          index === key ? !value : value
                      )
                  }
                : state
        })
        setCurrentState(nextState)
        props.onFilterChange(nextState)
    }

    return (
        <List>
            {currentState.map(filterAttribute => {
                return (
                    <ListItem key={filterAttribute.category}>
                        <FilterGroup
                            classes={classes}
                            {...filterAttribute}
                            handleChange={handleChange}
                        />
                    </ListItem>
                )
            })}
        </List>
    )
}

export default Filter
