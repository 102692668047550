import React from 'react'
import { Box, Link, Typography } from '@material-ui/core'
import useStyles from './ComingSoon.styles'

const ComingSoon = props => {
    const classes = useStyles()

    return (
        <Box textAlign="center" className="coming-soon">
            <Box marginTop={30}>
                <Typography variant="h2" color="primary">
                    Coming Soon
                </Typography>
                <Box marginTop={2}>
                    <Link href="https://www.facebook.com/sssapphires">Facebook</Link>
                </Box>
                <Box>
                    <Link href="https://www.instagram.com/sheahans/">Instagram</Link>
                </Box>
            </Box>
        </Box>
    )
}

export default ComingSoon
