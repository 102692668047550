import React from 'react'
import { useHistory } from 'react-router'
import {
    Box,
    Grid,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    CardActions,
    IconButton,
    Typography
} from '@material-ui/core'
import { ShoppingCart as ShoppingCartIcon } from '@material-ui/icons/'
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart'
import useStyles from './ProductList.styles'
import { CartContext } from 'context'

const ProductList = ({ stones, getStoneTitle, hasDetailView }) => {
    const { addCart, isInCart, removeCart } = React.useContext(CartContext)

    const history = useHistory()
    const handleProductClick = stone => {
        history.push(`/products/${stone.uuid}`)
    }

    const classes = useStyles()

    const sizes = hasDetailView
        ? { sm: 12, lg: 4 }
        : {
              sm: 4,
              md: 4,
              lg: 3
          }

    return (
        <Box className={classes.productList} padding={2}>
            <Grid container spacing={2}>
                {stones &&
                    stones.map(stone => {
                        const [primaryMediaThumbnail] = stone.primaryMedia
                        return (
                            <Grid item xs={12} {...sizes} key={stone.uuid}>
                                <Box className={classes.product}>
                                    <Card elevation={0} className={classes.card}>
                                        <CardActionArea onClick={() => handleProductClick(stone)}>
                                            <CardMedia
                                                className={classes.media}
                                                image={primaryMediaThumbnail}
                                                component="img"
                                                alt={getStoneTitle(stone)}
                                                title={getStoneTitle(stone)}
                                            ></CardMedia>
                                            <CardContent className={classes.content}>
                                                <Box
                                                    className={classes.info}
                                                    display="flex"
                                                    flexWrap="wrap"
                                                    justifyContent="space-between"
                                                >
                                                    <Box mr={1}>
                                                        <Typography align="left" variant="body1">
                                                            {stone.color}
                                                        </Typography>
                                                    </Box>
                                                    <Box mr={1}>
                                                        <Typography align="left" variant="body1">
                                                            {stone.weight}ct
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography align="left" variant="body1">
                                                            {stone.dimension?.replace(' mm', '')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions className={classes.actions}>
                                            <Typography
                                                variant="subtitle1"
                                                className={classes.model}
                                            >
                                                {stone.model}
                                            </Typography>
                                            <Box display="flex" alignItems="center">
                                                <Typography align="left" variant="body1">
                                                    ${stone.rate}/ct
                                                </Typography>

                                                {isInCart(stone.uuid) ? (
                                                    <IconButton
                                                        className={classes.cartRemoveIcon}
                                                        aria-label="remove from cart"
                                                        onClick={() => removeCart(stone)}
                                                    >
                                                        <RemoveShoppingCartIcon />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        className={classes.cartIcon}
                                                        aria-label="add to cart"
                                                        onClick={() => addCart(stone)}
                                                    >
                                                        <ShoppingCartIcon />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        </CardActions>
                                    </Card>
                                </Box>
                            </Grid>
                        )
                    })}
            </Grid>
        </Box>
    )
}

export default ProductList
