// import { useState } from 'react'
import { Box } from '@material-ui/core'
import AboutUs from 'components/AboutUs'
import Banner from 'components/Banner'
import ContactUs from 'components/ContactUs'
import Features from 'components/Features'
import useStyles from './Home.styles'

const Home = () => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Box>
                <Banner />
            </Box>
            <Box>
                <AboutUs />
            </Box>
            <Box>
                <Features />
            </Box>
            <Box>
                <ContactUs />
            </Box>
        </Box>
    )
}

export default Home
