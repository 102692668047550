import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    productList: {
    },
    product: {
        // paddingRight: theme.spacing(1),
        // paddingLeft: theme.spacing(1),
        // paddingBottom: theme.spacing(2)
    },
    card: {
        transition: 'all 0.5s',
        border: `1px solid ${theme.palette.grey[200]}`,
        '&:hover': {
            borderColor: theme.palette.primary.light
        },
        '& .MuiCardActionArea-root': {
            height: '24vh',
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-end',
            [theme.breakpoints.down('md')]: {
                height: '16vh',
            },
            [theme.breakpoints.down('lg')]: {
                height: '20vh',
            },

        },
        '&:hover .MuiCardContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
        }
    },
    media: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: '100%',
        width: '100%'
    },
    content: {
        transition: `all ${theme.transitions.duration.short}ms`,
        position: 'relative',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        color: 'white',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    model: {
        marginLeft: theme.spacing(1)
        // marginTop: theme.spacing(1)
    },
    info: {
        // marginLeft: theme.spacing(1),
        // marginTop: theme.spacing(1)
    },
    actions: {
        display: 'flex',
        // backgroundColor: 'rgba(0, 0, 0, 0.35)',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    cartIcon: {
        color: theme.palette.primary.main
        // '&:hover': {
        //     color: theme.palette.primary.main
        // }
    },
    cartRemoveIcon: {
        color: theme.palette.secondary.main,
    },
}))

export default useStyles
