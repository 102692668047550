import { getPrice } from 'config/tierPrice'
import { NO_MEDIA_PLACEHOLDER } from 'config/constants'

const getStoneTitle = stone => {
    return [stone.model, stone.color, stone.dimension, stone.weight, stone.origin].join(', ')
}

const fixMedia = media => {
    if (!media) media = NO_MEDIA_PLACEHOLDER

    if (typeof media === 'string') {
        media = [media, media]
    } else if (media.length === 1) {
        media = [media[0], media[0]]
    }

    return media
}

const fixMedias = stone => {
    stone.primaryMedia = fixMedia(stone.primaryMedia)
    stone.medias = stone.medias ? stone.medias.map(fixMedia) : [stone.primaryMedia ? stone.primaryMedia : NO_MEDIA_PLACEHOLDER]

    return stone
}

const refactorPrice = (price, color, tier) => {
    return getPrice(price, color, tier)
}

export { getStoneTitle, fixMedias, refactorPrice }
