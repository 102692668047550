import { COLORS, WEIGHTS } from './constants'

const weightSpans = [null, ...WEIGHTS].reduce((weightSpans, weight, index, weights) => {
    weightSpans.push([weight, index < weights.length - 1 ? weights[index + 1] : null])
    
    return weightSpans
}, [])

const filter = {
    color: COLORS,
    weight: weightSpans
}

let filterAttributes = []
const categories = Object.keys(filter)
categories.forEach(key => {
    filterAttributes.push({ category: key, values: filter[key] })
})

export default filterAttributes
