import { createMuiTheme, responsiveFontSizes, colors } from '@material-ui/core'
// import shadows from './shadows'
import typography from './typography'

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1440,
            xxl: 1920
        }
    },
    palette: {
        primary: {
            main: '#89CFF0',
            contrastText: '#fff'
        },
        // secondary: {
        //     main: '#000',
        //     contrastText: '#fff'
        // },
        background: {
            paper: '#fff',
            default: '#fff'
            // image: colors.cyan[100]
        }
        // text: {}
    },
    // shape: {},
    // shadows: ['none'],
    typography,
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*': {
                    boxSizing: 'border-box'
                    // margin: 0,
                    // padding: 0
                },
                html: {
                    '-webkit-font-smoothing': 'antialiased',
                    '-moz-osx-font-smoothing': 'grayscale',
                    // height: '100%',
                    // width: '100%'
                },
                body: {
                    // height: '100%',
                    // width: '100%'
                },
                // a: {
                //     color: colors.cyan[500],
                //     textDecoration: 'none',
                //     '&:hover': {
                //         textDecoration: 'underline'
                //     }
                // },
                // '#root': {
                //     height: '100%',
                //     width: '100%'
                // }
            }
        },
        MuiButton: {
            root: {
                // paddingLeft: 40,
                // paddingRight: 40,
                // paddingTop: 10,
                // paddingBottom: 10,
                borderRadius: 8
            }
        }
    }
})

theme.props = {
    MuiPaper: {
        elevation: 0,
        // square: true
    },
    MuiButton: {
        disableElevation: true
    },
    MuiLink: {
        underline: 'none'
    }
}

export default responsiveFontSizes(theme)
