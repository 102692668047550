import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(8),
        background: 'url(/images/background-net.png) bottom no-repeat, #89CFF0',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    feature: {
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(10)
        }
    },
    title: {
        fontSize: 35,
        borderLeft: '10px solid #000000',
        paddingLeft: theme.spacing(1)
    },
    loginButton: {
        // background: '#000',
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
        background: '#000',
        '&:hover': {
            background: '#111',
        }
    }
}))

export default useStyles
