import { Box, Button, Container, Typography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

import useStyles from './Banner.styles'

const Banner = () => {
    const classes = useStyles()

    return (
        <Box className={classes.banner}>
            <Container maxWidth="lg">
                <Box className={classes.welcome}>
                    <Typography variant="h1">WELCOME TO</Typography>
                    <Box marginTop={8}>
                        <Typography variant="h2">SHEAHAN STEPHEN SAPPHIRES INC.</Typography>
                    </Box>
                    {/* <Box marginTop={5}>
                        <Button
                            variant="contained"
                            className={classes.learnMoreButton}
                            to="/products"
                            component={RouterLink}
                        >
                            Learn More
                        </Button>
                    </Box> */}
                </Box>
            </Container>
        </Box>
    )
}

export default Banner
