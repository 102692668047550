import { createContext, useState, useContext } from 'react'
import { SnackbarContext } from './SnackbarContext'

export const CartContext = createContext({
  addCart: () => {},
  removeCart: () => {},
  cartItems: []
})

export const CartProvider = ({ children }) => {
  const { showMessage } = useContext(SnackbarContext)

  const [cartItems, setCartItem] = useState([])

  const addCart = ( item ) => {
    showMessage("Added into the cart.")
    let temp = cartItems
    let pos = temp.map(function(e) { return e.uuid; }).indexOf(item.uuid)
    if( pos === -1 )
      temp.push(item)
  }

  const isInCart = ( uuid ) => {
    let stoneIds = cartItems.map(item => item.uuid)
    return stoneIds.includes(uuid)
  }

  const removeCart = ( item ) => {
    showMessage("Removed from the cart")
    let temp = cartItems
    let pos = temp.map(function(e) { return e.uuid; }).indexOf(item.uuid)
    temp.splice(pos, 1)
    setCartItem(temp)
  }

  const checkoutCart = () => {
    showMessage("Your order form has been submitted. successfully. Thank you for your order!")
    setCartItem([])
  }

  const contextValue = {
    cartItems,
    addCart,
    removeCart,
    checkoutCart,
    isInCart
  }

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}
