import { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
import {
    Box,
    Grid,
    Paper,
    Hidden,
    Button,
    LinearProgress,
    Drawer,
    Container,
    CircularProgress
} from '@material-ui/core'
import { Scrollbars } from 'react-custom-scrollbars'
import useStyles from './Product.styles'
import FilterListIcon from '@material-ui/icons/FilterList'

import Filter from 'components/product/Filter/Filter'
import ProductList from 'components/product/ProductList'
import ProductDetail from 'components/product/ProductDetail'
import axios from 'utils/axios'
import { PAGE_SIZE } from 'config/constants'
import { SnackbarContext } from 'context/SnackbarContext'
import { UserContext } from 'context'
import camelcaseKeys from 'camelcase-keys'
import { fixMedias, refactorPrice, getStoneTitle } from 'utils/stone.helper'

import filterAttributes from 'config/filter'
import React from 'react'

const Product = () => {
    const history = useHistory()
    const location = useLocation()
    const { showMessage } = useContext(SnackbarContext)

    const { user } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const [stones, setStones] = useState([])
    const [offset, setOffset] = useState(0)
    const [noMoreLoadable, setNoMoreLoadable] = useState(false)

    const STONES_LOADER_DEBOUNCE_TIMEOUT = 200
    const [stonesLoaderDebounceHandler, setStonesLoaderDebounceHandler] = useState(null)
    const [stonesLoaderRequestSource, setStonesLoaderRequestSource] = useState(null)

    const [filterOpen, setFilterOpen] = useState(false)

    const [filterData, setFilterData] = useState('')

    const params = useParams()
    const [stone, setStone] = useState(null)
    const hasDetailView = stone != null
    const classes = useStyles()

    useEffect(() => {
        loadStones()
    }, [filterData, user])

    useEffect(() => {
        const uuid = params.uuid
        if (!uuid) {
            setStone(null)
            return
        }

        if (stone && stone.uuid === uuid) return

        const st = stones.find(stone => stone.uuid === uuid)
        if (st) {
            setStone(st)
            return
        }

        if (user === null) return

        const loadStone = () => {
            axios
                .get(`/stones/${uuid}`)
                .then(({ data }) => {
                    let stone = camelcaseKeys(data, { deep: true })

                    stone.rate = refactorPrice(stone.rate, stone.color, user?.tier)
                    stone = fixMedias(stone)

                    setStone(stone)
                })
                .catch(err => console.log(err))
        }

        loadStone()
    }, [params, user])

    const createSearchParams = () => {
        const colorQuery = filterData.color?.join(',')
        const weightQuery = filterData.weight?.map(w => w.join(':')).join(',')
        const params = {
            limit: offset ? PAGE_SIZE : PAGE_SIZE * 2,
            offset,
            colors: colorQuery,
            weights: weightQuery
        }

        return params
    }
    const loadStones = () => {
        if (user === null) return

        const params = createSearchParams()

        setLoading(true)

        const source = axios.CancelToken.source()
        setStonesLoaderRequestSource(source)

        axios
            .get(`/stones`, { params, cancelToken: source.token })
            .then(({ data }) => {
                data = camelcaseKeys(data, { deep: true })

                data = data.map(stone => {
                    stone.rate = refactorPrice(stone.rate, stone.color, user?.tier)
                    return fixMedias(stone)
                })

                const count = data.length
                setOffset(offset => offset + count)
                setStones(stones => stones.concat(data))
                if (count < PAGE_SIZE) setNoMoreLoadable(true)

                finishStonesLoading()
            })
            .catch(e => {
                if (!axios.isCancel(e)) {
                    console.log(e)
                    showMessage('Failed to load the stones.', 'error')

                    finishStonesLoading()
                }
            })
    }

    const cancelStonesLoading = () => {
        if (stonesLoaderRequestSource) {
            stonesLoaderRequestSource.cancel(
                'The request has been cancelled due to another new request'
            )
        }
        if (stonesLoaderDebounceHandler) {
            clearTimeout(stonesLoaderDebounceHandler)
            setStonesLoaderDebounceHandler(null)
        }

        finishStonesLoading()
    }

    const finishStonesLoading = () => {
        setStonesLoaderRequestSource(null)
        setLoading(false)
    }

    const handleFilterChange = filterState => {
        cancelStonesLoading()

        let filterData = {}
        filterState.forEach(state => {
            state.checkedValues.forEach((value, index) => {
                if (value) {
                    filterData.hasOwnProperty(state.category)
                        ? filterData[state.category].push(state.values[index])
                        : (filterData[state.category] = [state.values[index]])
                }
            })
        })

        setOffset(0)
        setStones([])
        setStone(null)
        setNoMoreLoadable(false)
        setFilterData(filterData)
        if (location.pathname !== '/products') {
            history.push('/products')
        }
    }

    return (
        <Box className={classes.root}>
            <Hidden mdUp>
                <Button
                    className={classes.filterIcon}
                    color="primary"
                    variant="contained"
                    aria-label="filter-icon"
                    onClick={() => setFilterOpen(true)}
                >
                    <FilterListIcon />
                </Button>
                <Drawer anchor="left" open={filterOpen} onClose={() => setFilterOpen(false)}>
                    <Paper className={classes.paper}>
                        <Filter
                            onFilterChange={handleFilterChange}
                            filterAttributes={filterAttributes}
                        />
                    </Paper>
                </Drawer>
            </Hidden>
            <Container maxWidth="xl" className={classes.container}>
                <Paper className={classes.filter}>
                    <Filter
                        onFilterChange={handleFilterChange}
                        filterAttributes={filterAttributes}
                    />
                </Paper>
                <Paper className={classes.content}>
                    {!hasDetailView ? (
                        <>
                            <ProductList
                                stones={stones}
                                getStoneTitle={getStoneTitle}
                                hasDetailView={hasDetailView}
                            />
                            {!noMoreLoadable && (
                                <Box className={classes.loadMoreWrapper}>
                                    <Button
                                        disabled={loading}
                                        onClick={loadStones}
                                        variant="outlined"
                                        color="primary"
                                        className={classes.loadMoreButton}
                                        size="large"
                                    >
                                        Load more
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            className={classes.progress}
                                            size={32}
                                            color="secondary"
                                        />
                                    )}
                                </Box>
                            )}
                        </>
                    ) : (
                        <ProductDetail stone={stone} getStoneTitle={getStoneTitle} />
                    )}
                </Paper>
            </Container>
        </Box>
    )
}

export default Product
