import { Box, Button, Container, Typography } from '@material-ui/core'

import useStyles from './AboutUs.styles'

const AboutUs = () => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Container maxWidth="xl">
                <Box className={classes.content}>
                    <Typography variant="h3">ABOUT US</Typography>
                    <Box marginTop={2}>
                        <Typography variant="body2">
                            Owner/CEO Sheahan Stephen is an expert in Sri Lankan gemstone mining. He
                            has published articles and given lectures for the Gemological Institute
                            of America, American Gem Society, American Gem Trade Association, and
                            GemGuide. Sheahan previously sat on the Board of Directors for GIA San
                            Francisco as Vice President and AGS San Francisco as Secretary.
                            <br />
                            <br />
                            Sheahan Stephen Sapphires is headquartered in San Francisco, California
                            and offers sapphires sourced from all over the world from reputable,
                            verifiable mines and suppliers. We currently have offices in San
                            Francisco, Portland, Ratnapura and Beruwala. Ethics are a key component
                            of our business model. We carefully select who we partner with to ensure
                            that our gemstones do not support terrorist or human trafficking groups.
                            <br />
                            <br />
                            Founded in 2006, our goal has been to provide unparalleled, intrinsic
                            value by using a human first business model along with top quality,
                            responsibly sourced sapphires. We strive to be a compassionate
                            organization and create opportunities for everyone involved to improve
                            their lives by providing knowledge and skills that can be passed to the
                            next generation.
                            <br />
                            <br />
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default AboutUs
