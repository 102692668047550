import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Typography
} from '@material-ui/core'
import { Facebook, Instagram, LinkedIn } from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom'

import useStyles from './ContactUs.styles'

const companyNames = [
    'Sheahan Stephen Mining',
    'Sheahan Stephen Lapidary PVT LTD',
    'Sheahan Stephen Sapphires INC'
]

const AboutUs = () => {
    const classes = useStyles()

    const socialLinks = [
        [
            'Facebook',
            'https://www.facebook.com/sheahan.stephen',
            classes.facebookIcon,
            <Facebook />
        ],
        ['Instagram', 'https://www.instagram.com/sheahans/', classes.instagramIcon, <Instagram />],
        [
            'LinkedIn',
            'https://www.linkedin.com/in/sheahanstephen/',
            classes.linkedInIcon,
            <LinkedIn />
        ]
    ]
    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box className={classes.content}>
                    <Grid container spacing={8}>
                        <Grid xs={12} item md={4}>
                            <Box className={classes.contact}>
                                <Box className={classes.logo}>
                                    <img src="/images/logo.png" className="responsive" alt="logo" />
                                </Box>
                                <Box marginTop={2} display="inline-block">
                                    {companyNames.map((companyName, i) => (
                                        <Box
                                            key={`company-name-${i}`}
                                            className={classes.companyName}
                                        >
                                            <Typography variant="body1">{companyName}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12} item md={4}>
                            <Box className={classes.contact}>
                                <Typography variant="h3" className={classes.title}>
                                    LET'S TALK!
                                </Typography>
                                <Box
                                    marginTop={3}
                                    className={classes.content}
                                    display="inline-block"
                                >
                                    {socialLinks.map(([name, link, avatarClassName, icon], i) => (
                                        <Link
                                            key={`social-${i}`}
                                            href={link}
                                            className={classes.socialLink}
                                        >
                                            <Avatar className={avatarClassName}>{icon}</Avatar>
                                            <Typography
                                                variant="body1"
                                                className={classes.socialName}
                                            >
                                                {name}
                                            </Typography>
                                        </Link>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12} item md={4}>
                            <Box className={classes.contact}>
                                <Typography variant="h3" className={classes.title}>
                                    CONTACT US
                                </Typography>
                                <Box marginTop={3} className={classes.content}>
                                    <Typography variant="body1">
                                        10 Post St. Suite #312
                                        <br /> San Francisco, CA 94108
                                    </Typography>
                                </Box>
                                <Box marginTop={3}>
                                    <Typography variant="body1">
                                        <span className={classes.elementTitle}>Phone:</span>
                                        &nbsp;&nbsp;&nbsp;503-805-6832 <br />
                                        <span className={classes.elementTitle}>E-mail:</span>
                                        &nbsp;&nbsp;&nbsp;sheahan@sssapphires.com
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default AboutUs
