import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider, UserProvider, CartProvider } from 'context'
import Snackbar from 'components/shared/Snackbar'

import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from 'theme'

import AppRouter from 'router'
import AuthHelper from 'utils/auth.helper'

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
                <UserProvider>
                    <CartProvider>
                        <Router>
                            <AuthHelper />
                            <AppRouter />
                        </Router>
                    </CartProvider>
                    <Snackbar />
                </UserProvider>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default App
