import { Hidden } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

import useStyles from './Logo.styles'

const Logo = () => {
    const classes = useStyles()

    return (
        <RouterLink to="/" className={classes.logoLink}>
            <img className={classes.logo} src="/logo.webp" alt="Sheahan Stephen Sapphires Inc." />
            <Hidden xsDown>
                <img
                    className={classes.logoTitle}
                    src="/logo-title.webp"
                    alt="Sheahan Stephen Sapphires Inc."
                />
            </Hidden>
        </RouterLink>
    )
}

export default Logo
